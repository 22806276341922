import { PlayerAudio } from "../interfaces/player/player.interface";

export const setAudioFilterVisibility = (language: string, topicLabel: string, audioQueue: PlayerAudio[]): PlayerAudio[] => {
    let newAudioQueue = [...audioQueue];

    try {
        let order = 0;
        for (let audio of newAudioQueue) {
            if (audio?.language === language && (
                audio?.topicName === topicLabel || topicLabel === '' || topicLabel.toLowerCase().includes('all')
            )) {
                audio.order = order;
                order++;
                audio.visible = true;
            }
            else {
                audio.order = -1;
                audio.visible = false;
            }
        }

    } catch (error) {
        console.error(error);
    }

    const updatedAudioQueue = Array.from(new Set(newAudioQueue));

    return updatedAudioQueue;
}
