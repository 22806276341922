
// translation files
import messages_en from '../translations/en.json';
import messages_es from '../translations/es.json';
import messages_fr from '../translations/fr.json';

// get topic description for a given language messages object
const getLangTopics = (messages: object): string[] => {
    let topics: string[] = [];
    for (const key in messages) {
        if (key.includes('topics-id')) {
            const value: string = messages[key as keyof typeof messages];
            topics.push(value.toLowerCase());
        }
    }
    return topics;
}

export const playerCommandsMapping = [
    {
        language: 'en',
        playerCommands: {
            wakeup: [
                'what are the news',
                'play the news',
                'news',
                'hey cerence',
                'hey odia',
            ],
            play: [
                'continue playing',
                'continue',
                'just play',
                'okay play',
                'play article',
                'play it',
                'play the article',
                'play',
                'resume',
                'resume',
                'start playing',
                'start',
            ],
            pause: [
                'make a pause',
                'okay stop',
                'pause',
                'please stop',
                'pose',
                'star',
                'stop',
                'stuff',
            ],
            next: [
                'go forward',
                'go to next article',
                'next article',
                'next',
                'play next',
                'play the next article',
                'what the next article',
            ],
            previous: [
                'go back',
                'go to previews article',
                'go to previews',
                'go to previous article',
                'go to previous',
                'play previews',
                'play previous',
                'play the previews',
                'previews',
                'previous article',
                'previous',
                'what the previews article',
                'what the previous article',
            ],
            changeTopic: [
                'change the topic',
                'change the topics',
                'change the topix',
                'change thematic',
                'change to matic',
                'change topic',
                'change topics',
                'change topix',
                'filter topic',
                'filter topics',
                'jason topic',
                'menu',
                'new thematic',
                'new topic',
                'other thematic',
                'other topic',
                'other topics',
                'other topix',
                'select topic',
                'select topics',
                'select topix',
                'show me the topics',
                'show me the topix',
                'show other topics',
                'show other topix',
                'show the menu',
                'show topics',
                'show topix',
                'team selection',
                'thematic',
                'theme selection',
                'topic change',
                'topic selection',
                'topic',
                'topics selection',
                'topics',
                'topix selection',
                'topix',
            ],
            topicSelect: [
                'all news',
                'all teams',
                'all themes',
                'all topics',
                'all topix',
                'play all news',
                'play all',
            ].concat(getLangTopics(messages_en))
        }
    },
    {
        language: 'es',
        playerCommands: {
            wakeup: [
                'noticias',
                'noticias del día',
                'reproduce las noticias',
                'hey cerence',
                'hey odia',
            ],
            play: ['reproduce', 'reproducir', 'continua', 'continúa', 'sigue'],
            pause: ['pausa', 'pausar', 'para', 'parar'],
            next: [
                'siguiente artículo',
                'siguiente',
            ],
            previous: [
                'anterior artículo',
                'anterior',
                'artículo anterior',
            ],
            changeTopic: [
                'cambia de tema',
                'cambiar categoría',
                'cambiar el tema',
                'cambiar tema',
                'categoría',
                'selecciona categoría',
                'selecciona tema',
                'seleccionar categoría',
                'seleccionar tema',
                'selección de categoría',
                'selección de tema',
                'tema',
                'temas',
            ],
            topicSelect: ['todos', 'todos los temas', 'todo'].concat(getLangTopics(messages_es))
        }
    },
    {
        language: 'fr',
        playerCommands: {
            wakeup: [
                'quelles sont les informations',
                'quelles sont les nouvelles du jour',
                'donne moi les nouvelles',
                'information',
                'information du jour',
                'actualité du jour',
                'écouter l\'actualité',
                'lire l\'actualité',
                'la presse du jour',
                'presse du jour',
                'lire la presse',
                'informations',
                'info',
                'infos',
                'hey cerence',
                'hey odia',
            ],
            play: ['lire', 'lit', 'dire', 'joue', 'jouer', 'lecture', 'play', 'resume', 'continue', 'start', 'start playing'],
            pause: ['pause', 'pose', 'stop', 'stoppe', 'arrêt', 'arrête', 'chut'],
            next: ['suivant', 'next', 'play next'],
            previous: ['précédent', 'previous', 'play previous', 'before'],
            changeTopic: ['changer de sujet',
                'sujet',
                'changer de thème',
                'thème',
                'choix du thème',
                'changer de thème',
                'autre thème',
                'autres thème',
                'changer de thématique',
                'thématique',
                'rubrique',
                'changer de rubrique',
                'rubrique',
                'menu',],
            topicSelect: ['tous', 'tous sujets', 'tous les thèmes'].concat(getLangTopics(messages_fr))
        }
    },
]

export const getPlayerCommandList = (language: string): string[] => {
    const langCode = language;

    let commandList: string[] = [];

    const langCommands = playerCommandsMapping.filter(
        commands => commands.language === langCode
    )[0];


    if (langCommands) {
        const playerCommands = langCommands.playerCommands
        // eslint-disable-next-line
        for (const [key, values] of Object.entries(playerCommands)) {
            commandList = commandList.concat(values);
        }
    }

    return commandList;
}

export const getCommandActionType = (command: string, language: string): string => {
    const langCode = language.substring(0, 2);

    let commandType = '';
    const langCommands = playerCommandsMapping.filter(
        commands => commands.language === langCode
    )[0];

    if (langCommands) {
        const playerCommands = langCommands.playerCommands
        // eslint-disable-next-line
        for (const [key, values] of Object.entries(playerCommands)) {
            if (values.includes(command)) {
                commandType = key;
                break;
            }
        }
    }

    return commandType;
}

export const mapTopicCommand = (command: string, language: string): string => {
    const langCode = language.substring(0, 2);

    let mappedCommand = '';
    let messages: object;

    switch (langCode) {
        case 'fr':
            messages = { ...messages_fr }
            break;
        case 'es':
            messages = { ...messages_es }
            break;
        default:
            messages = { ...messages_en }
            break;
    }

    // get the topic id
    let topicId = '';
    for (const [key, values] of Object.entries(messages)) {
        if (key.includes('topics-id') && values.toLowerCase().includes(command.toLowerCase())) {
            topicId = key;
            break;
        }
    }

    // get the english topic english description with the found id
    mappedCommand = messages_en[topicId as keyof typeof messages] || '';

    return mappedCommand.toLowerCase();
}