import { useState, useContext, useEffect, useRef } from "react";

// modules
import { FormattedMessage } from "react-intl";

// components
import { Player } from "../../player/Player";
import { PlayerButtons } from "../../playerButtons/PlayerButtons";
import { PlayerSlider } from "../../player/playerSlider/PlayerSlider";
import { SpeechButton } from "../../speechButton/SpeechButton";
import { AudioGuide } from "../../speechPage/audioGuide/AudioGuide";
import { TopBar } from "../../topBar/TopBar";
import { TopicModal } from "./topicModal/TopicModal";
import { SpeechFeedback } from "../../speechFeedback/SpeechFeedback";
import { PlaylistDetail } from "./playlistDetail/PlaylistDetail";
import { LargeAudioCard } from "../audioDescriptor/LargeAudioCard";

// mui
import { useTheme, styled, alpha } from "@mui/material/styles";
import {
  Box,
  Button,
  ToggleButtonGroup,
  ToggleButton,
  Container,
  CssBaseline,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import FeedIcon from "@mui/icons-material/Feed";
import BurstModeIcon from "@mui/icons-material/BurstMode";

// img
import logoOdia from "../../../img/logo-odia-original.png";
import logoOdiaLight from "../../../img/logo-odia-original-light.png";

// contexts
import { AppContext } from "../../../contexts/AppContext";
import { PlayerContext } from "../../../contexts/PlayerContext";

// styles
import "../../styles/truncate-text.css";

// translation files
import messages_en from "../../../translations/en.json";
import messages_es from "../../../translations/es.json";
import messages_fr from "../../../translations/fr.json";

// interfaces
import {
  PlayerData,
  PlayerStylePlaylist,
} from "../../../interfaces/player/player.interface";

// utils
import { getSpeechCommands } from "../../../utils/speech/getSpeechCommands";
import { processCommand } from "../../../utils/speech/processCommand";
import { useLocation } from "react-router-dom";
import {
  updatePlaylistOnUrlChange,
  updatePlaylistURL,
} from "../updatePlaylist";

const backendRoute: string = process.env.REACT_APP_BACKEND_ROUTE || "";
const audioGuideUrl = `${backendRoute}/audioGuide`;

const Widget = styled("div")(() => ({
  width: "98vw",
  maxWidth: "100%",
  margin: "auto",
  position: "relative",
  zIndex: 1,
}));

const messages = {
  en: messages_en,
  es: messages_es,
  fr: messages_fr,
};

export const CompletePlaylist = () => {
  const theme = useTheme();
  const { palette } = theme;

  const {
    appLang,
    appModals,
    externalCommand,
    audioGuide,
    appRecording,
    appTopics,
    colorMode,
  } = useContext(AppContext);
  const { lang } = appLang;
  const { current: currentContextLanguage } = lang;
  const { topicModal } = appModals;
  const { openTopicModal, setOpenTopicModal } = topicModal;
  const {
    command: extCommand,
    setCommand: setExtCommand,
    playAudioGuide,
    setPlayAudioGuide,
  } = externalCommand;
  const {
    audioGuideActivated,
    setAudioGuideActivated,
    audioGuideNextSrc,
    setAudioGuideNextSrc,
  } = audioGuide;
  const { topics: topicList } = appTopics;
  const { themeMode, setDarkMode, setLightMode } = colorMode;

  const { speechCommands } = getSpeechCommands(currentContextLanguage);
  const commands = useRef(speechCommands);

  const { setVoiceCommand } = appRecording;

  const playerContext = useContext(PlayerContext);
  const { style, data } = playerContext;
  const { currentAudio, audioQueue } = data as PlayerData;
  const { visual, buttons, playlist } = style;

  const {
    boxShadow,
    primaryColor,
    showCarDisplayModal,
    showOdiaLabel,
    showOdiaLabelIcon,
    linkToWebsite,
    showPlayerDescription,
    showPlayerTitle,
    truncatePlayerDescription,
    truncatePlayerTitle,
  } = visual;

  const { showHomeButton, showSpeechButton } = buttons;
  const {
    defaultPlaylistView,
    showPlaylistAudioDate,
    showPlaylistAudioDuration,
    showPlaylistAudioPublisher,
    showPlaylistAudioTopic,
    showPlaylistHeader,
    showPlaylistImages,
    showPlaylistTopicSelector,
  } = playlist as PlayerStylePlaylist;

  const location = useLocation();

  // update playlist on url change
  useEffect(() => {
    updatePlaylistOnUrlChange(
      setAudioGuideActivated,
      setDarkMode,
      setLightMode,
      location.search
    );
    // eslint-disable-next-line
  }, []);

  // update playlist url on language, theme or audio guide status change
  useEffect(() => {
    updatePlaylistURL(
      audioGuideActivated,
      themeMode.mode,
      location.search,
      location.pathname
    );
    // eslint-disable-next-line
  }, [themeMode, audioGuideActivated, location.search, location.pathname]);

  // update speech commands on language change
  useEffect(() => {
    commands.current = speechCommands;
    // eslint-disable-next-line
  }, [speechCommands, currentContextLanguage]);

  useEffect(() => {
    if (extCommand.pendingProcessing) {
      processCommand(
        extCommand.message,
        commands.current,
        1.0,
        currentContextLanguage,
        audioGuideActivated,
        playerContext,
        setVoiceCommand,
        topicList,
        setAudioGuideNextSrc,
        setPlayAudioGuide,
        setOpenTopicModal
      );
      setExtCommand((state) => ({
        ...state,
        pendingProcessing: false,
      }));
    }
    // eslint-disable-next-line
  }, [extCommand]);

  // handle new audio guide commands when they are pending to process in the state
  useEffect(() => {
    document.getElementById("pauseButton")?.click();
    if (playAudioGuide && audioGuideActivated) {
      document.getElementById("pauseButton")?.click();
      const audioSrc = `${audioGuideUrl}?lang=${currentContextLanguage}&${audioGuideNextSrc}`;
      const audio: HTMLAudioElement = new Audio(audioSrc);
      audio.play();
      audio.onended = () => {
        if (audioGuideNextSrc !== "context=topicSelection") {
          document.getElementById("playButton")?.click();
        }
      };
      setPlayAudioGuide(false);
    }
    // eslint-disable-next-line
  }, [playAudioGuide]);

  const [playlistView, setPlaylistView] = useState(defaultPlaylistView);

  let allTopicLabel = messages["en"]["app.playlist-topic-selector-all"];

  let languageAudios = audioQueue?.filter(
    (audio) =>
      audio.language === currentContextLanguage
  );

  const topics: number[] = [];
  if (languageAudios?.length > 0) {
    for (let audio of languageAudios) {
      if (!topics.includes(audio.topicId)) {
        topics.push(audio.topicId);
      }
    }
  }

  const handleTopicModalOpen = () => setOpenTopicModal(true);

  const playlistShadow =
    palette.mode !== "dark"
      ? "-5px 0px 8px -4px rgba(0,0,0,0.25)"
      : "-5px 0px 12px -4px rgba(0,0,0,0.7)";
  const activateHomeButton = !showCarDisplayModal ? false : showHomeButton;

  return (
    <>
      <CssBaseline />
      {showPlaylistHeader && (
        <TopBar
          visualStyle={{
            showHomeButton: activateHomeButton,
            showOdiaLabel,
            linkToWebsite,
          }}
        />
      )}

      <Container maxWidth="xl">
        <Box
          aria-label="completePlaylist"
          sx={{
            borderRadius: 4,
            boxShadow,
            backgroundColor: palette.background.paper,
            maxWidth: "98vw",
            minHeight: "60vh",
            overflow: "hidden",
            justifyContent: "center",
            display: "flex",
            mt: 2,
          }}
        >
          <Box
            sx={{
              bottom: 60,
              position: "fixed",
              zIndex: "tooltip",
            }}
          >
            <SpeechButton />
          </Box>

          <Player />

          <Widget>
            <Stack
              direction="row"
              alignItems="flex-start"
              justifyContent="space-evenly"
              marginLeft="20px"
            >
              {/* Current audio section */}
              <Stack
                spacing={0}
                direction="column"
                justifyContent="center"
                sx={{ mb: 1, px: 1, pt: 5 }}
                width="40%"
              >
                <PlayerButtons />
                <PlayerSlider />
                <Divider variant="middle" sx={{ mt: 3, mb: 3 }} />
                {currentAudio && (
                  <LargeAudioCard
                    audio={currentAudio}
                    visualStyle={{
                      showTitle: showPlayerTitle,
                      showDescription: showPlayerDescription,
                      showDate: showPlaylistAudioDate,
                      showDuration: showPlaylistAudioDuration,
                      showPublisher: showPlaylistAudioPublisher,
                      showTopic: showPlaylistAudioTopic,
                      showImage: showPlaylistImages,
                      truncateTitle: truncatePlayerTitle,
                      truncateDescription: truncatePlayerDescription,
                    }}
                  />
                )}
                <Divider variant="middle" sx={{ mt: 3, mb: 2 }} />

                {showSpeechButton && (
                  <Stack
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Box alignItems="center" justifyContent="center">
                      <AudioGuide switchType="switch" />
                    </Box>

                    <Box alignItems="center" justifyContent="center">
                      <SpeechFeedback />
                    </Box>
                  </Stack>
                )}
              </Stack>

              {/* Playlist section */}
              <Box
                width="60%"
                py={1}
                pl={1}
                ml={3}
                sx={{
                  background: "rgba(0, 0, 0, 0.03)",
                  minHeight: 560,
                  maxHeight: "85vh",
                  overflow: "auto",
                  scrollbarWidth: "thin",
                  overflowY: "auto",
                  "&::-webkit-scrollbar": {
                    width: "6px",
                  },
                  "&::-webkit-scrollbar-track": {
                    boxShadow: "inset 0 0 1px rgba(0,0,0,0.00)",
                    ml: 1,
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: alpha(primaryColor, 0.2),
                    borderRadius: "5px",
                  },
                }}
                boxShadow={playlistShadow}
              >
                <Stack direction="column">
                  {(showOdiaLabel ||
                    showOdiaLabelIcon ||
                    showPlaylistTopicSelector) && (
                    <Box
                      pr={2}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {showPlaylistTopicSelector && topicList.length > 1 && (
                        <Button
                          id="topicMenuOpen"
                          color="inherit"
                          onClick={handleTopicModalOpen}
                          startIcon={<FilterListIcon />}
                          sx={
                            playlistView === "detail"
                              ? { fontSize: 15 }
                              : { fontSize: 22 }
                          }
                        >
                          <FormattedMessage id="app.playlist-topic-button" />
                        </Button>
                      )}
                      <Box
                        pl={1}
                        sx={{
                          display: "flex",
                          justifyContent: "end",
                        }}
                      >
                        {(showOdiaLabel || showOdiaLabelIcon) && (
                          <Box mr={3}>
                            {showOdiaLabel && (
                              <Typography
                                aria-label="logo text"
                                variant="caption"
                                color="text.secondary"
                                sx={{ verticalAlign: "bottom" }}
                              >
                                <FormattedMessage id="app.player-poweredBy-label" />
                                &nbsp;
                              </Typography>
                            )}
                            {showOdiaLabelIcon && linkToWebsite ? (
                              <a
                                href={`https://www.odialab.com/${
                                  lang.current === "fr-FR" ? "fr" : "en"
                                }/`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  aria-label="logo icon"
                                  src={
                                    palette.mode === "dark"
                                      ? logoOdiaLight
                                      : logoOdia
                                  }
                                  alt="logo"
                                  style={{
                                    height: "15px",
                                    display: "block",
                                    marginTop: "2px",
                                  }}
                                />
                              </a>
                            ) : (
                              <img
                                aria-label="logo icon"
                                src={
                                  palette.mode === "dark"
                                    ? logoOdiaLight
                                    : logoOdia
                                }
                                alt="logo"
                                style={{ height: "15px", marginTop: "2px" }}
                              />
                            )}
                          </Box>
                        )}

                        <ToggleButtonGroup value={playlistView}>
                          <ToggleButton
                            value="detail"
                            onClick={() => setPlaylistView("detail")}
                          >
                            <FeedIcon
                              htmlColor={
                                playlistView === "detail"
                                  ? palette.secondary.light
                                  : "inherit"
                              }
                            />
                          </ToggleButton>
                          <ToggleButton
                            value="visual"
                            onClick={() => setPlaylistView("visual")}
                          >
                            <BurstModeIcon
                              htmlColor={
                                playlistView === "visual"
                                  ? palette.secondary.light
                                  : "inherit"
                              }
                            />
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </Box>
                    </Box>
                  )}

                  {/* playlist detail */}
                  <PlaylistDetail view={playlistView} />
                </Stack>
              </Box>
            </Stack>
          </Widget>
        </Box>
      </Container>
      {showPlaylistTopicSelector && topics.length > 1 && (
        <TopicModal
          open={openTopicModal}
          setOpen={setOpenTopicModal}
          allTopicLabel={allTopicLabel}
          topics={topics}
        />
      )}
    </>
  );
};
