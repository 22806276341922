// interfaces
import { Player, PlayerAudio, PlayerData, PlayerProps } from '../../interfaces/player/player.interface';
import { Topic } from '../../interfaces/player/playerRequest.interface';

// utils
import { setAudioFilterVisibility } from '../../utils/setAudioFilterVisibility';
import { getTopicDescription } from '../../utils/topicUtils';


export const handleSkipTo = (skipTo: number, player: Player) => {
    const { data, props } = player;
    const { currentAudio, audioQueue } = data as PlayerData;
    const { setPlayerState, setPlayerDataState } = props as PlayerProps;

    const { language } = currentAudio;
    const languageAudios = audioQueue.filter(audio => audio.language === language);
    const otherAudios = audioQueue.filter(audio => audio.language !== language);

    const visibleAudios = languageAudios?.filter(audio => audio.visible);
    if (visibleAudios && currentAudio) {

        let sortedAudios = visibleAudios.sort((a, b) => a.order - b.order);
        let nextAudio: PlayerAudio[] = []

        // if currently playing audio is no longer visible in playlist, next audio should be the first in playlist
        if (!isCurrentAudioVisible(currentAudio, visibleAudios))
            nextAudio = sortedAudios.filter(audio => audio.order === 0);
        else
            nextAudio = sortedAudios.filter(audio => audio.order === currentAudio.order + skipTo);

        // audio not found: selected is either the last or the first
        if (nextAudio?.length === 0) {
            if (skipTo > 0) nextAudio = [sortedAudios[0]];
            if (skipTo < 0) nextAudio = [sortedAudios[sortedAudios.length - 1]];
        }

        let nextAudioOrder = nextAudio[0] ? nextAudio[0].order : 0;

        let newCurrentAudio = { ...currentAudio }
        const newLanguageAudios = [...languageAudios]

        for (let audio of newLanguageAudios) {
            audio.autoPlay = false;
            if (audio.order === nextAudioOrder) {
                audio.selected = true;
                newCurrentAudio = { ...audio }
            } else {
                audio.selected = false;
            }
        }

        const newAudioQueue = [
            ...newLanguageAudios,
            ...otherAudios
        ]

        setPlayerState((state) => ({
            ...state,
            url: newCurrentAudio.audioUrl,
            played: 0,
            loaded: 0,
            duration: 0,
            loadAgain: false,
            playing: true
        }))
        setPlayerDataState({
            currentAudio: newCurrentAudio,
            audioQueue: newAudioQueue
        });
    }
}


export const handleAudioSelect = (audioId: number, player: Player, language: string, displayAudioContent = false) => {
    const { data, props } = player;
    const { audioQueue, currentAudio } = data as PlayerData;
    const { setPlayerState, setPlayerDataState, setShowAudioTextContent } = props as PlayerProps;

    const languageAudios = audioQueue.filter(audio => audio.language === language);
    const otherAudios = audioQueue.filter(audio => audio.language !== language);

    if (languageAudios.length > 0) {
        const newSelectedAudio = languageAudios.filter(audio => audio.id === audioId);

        if (newSelectedAudio.length > 0) {
            newSelectedAudio[0].selected = true

            const newLanguageAudios = [...languageAudios];
            for (let audio of newLanguageAudios) {
                audio.autoPlay = false;
                audio.selected = false;
                if (audio.id === newSelectedAudio[0].id) {
                    audio.selected = true;
                }
            }

            const newAudioQueue = [
                ...newLanguageAudios,
                ...otherAudios
            ]

            if (currentAudio.id !== audioId) {
                setPlayerState((state) => ({
                    ...state,
                    url: newSelectedAudio[0].audioUrl,
                    played: 0,
                    loaded: 0,
                    duration: 0,
                    loadAgain: false
                }));
            }
            setPlayerState((state) => ({
                ...state,
                playing: true
            }));


            setPlayerDataState({
                currentAudio: newSelectedAudio[0],
                audioQueue: newAudioQueue
            });

            if (displayAudioContent) {
                setShowAudioTextContent(true);
            }
        }
    }
}

export const handlePlayNext = (player: Player) => {
    const { data, props } = player;

    const { currentAudio, audioQueue } = data as PlayerData;
    const { setPlayerState, setPlayerDataState } = props as PlayerProps;

    const { language } = currentAudio;

    const languageAudios = audioQueue.filter(audio => audio.language === language);
    const otherAudios = audioQueue.filter(audio => audio.language !== language);
    let newCurrentAudio = { ...currentAudio }

    if (languageAudios.length > 0) {
        const selectedAudio = languageAudios.filter(audio => audio.selected)
        let nextAudio = languageAudios.filter(audio => audio.order > selectedAudio[0].order)
        let nextSortedAudios = nextAudio.sort((a, b) => a.order - b.order);
        // play next only if there is a pending audio
        if (nextSortedAudios.length > 0) {
            let newLanguageAudios = [...languageAudios];
            for (let audio of newLanguageAudios) {
                if (audio.order === nextSortedAudios[0].order) {
                    audio.selected = true
                    audio.autoPlay = true
                    newCurrentAudio = { ...audio }
                } else {
                    audio.selected = false
                    audio.autoPlay = false
                }
            }

            const newAudioQueue = [
                ...newLanguageAudios,
                ...otherAudios
            ]

            setPlayerState((state) => ({
                ...state,
                url: newCurrentAudio.audioUrl,
                played: 0,
                playing: true,
                loaded: 0,
                duration: 0,
                loadAgain: false,
                isAlreadyPlayed: false
            }))
            setPlayerDataState({
                currentAudio: newCurrentAudio,
                audioQueue: newAudioQueue
            });
        }
    }
}


export const handleTopicSelection = (
    player: Player,
    language: string,
    setOpenTopicModal: React.Dispatch<React.SetStateAction<boolean>>,
    topics: Topic[],
    topicId: number,
    topicLabel: string,
    playFirstAudio = false
) => {
    // close topic modal/selector
    setOpenTopicModal(false);

    const { props, data } = player;
    const { setPlayerState, setPlayerDataState } = props as PlayerProps;
    const { audioQueue } = data as PlayerData;
    const topicDescription = topicLabel? topicLabel : getTopicDescription(topicId, topics);
    

    setPlayerState((state) => ({
        ...state,
        topicIndex: topicId,
        topic: topicDescription
    }));

    const newAudioQueue = setAudioFilterVisibility(language, topicDescription, audioQueue);
    setPlayerDataState((state) => ({
        ...state,
        audioQueue: newAudioQueue
    }))

    if (playFirstAudio) {
        // play first visible audio of the queue
        const visibleAudios = newAudioQueue.filter(audio => audio.visible)
        if (visibleAudios.length > 0) {
            handleAudioSelect(visibleAudios[0].id, player, language);
        }
    }
}

const isCurrentAudioVisible = (currentAudio: PlayerAudio, visibleAudios: PlayerAudio[]) => {
    return visibleAudios.map(a => a.id).includes(currentAudio.id);
}
