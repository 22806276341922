import { useContext } from 'react';

// modules
import { FormattedMessage } from 'react-intl';

// components
import { AudioDescriptor } from '../../../audioDescriptor/AudioDescriptor';

// contexts
import { AppContext } from '../../../../../contexts/AppContext';
import { PlayerContext } from '../../../../../contexts/PlayerContext';

// interfaces
import { PlayerData, PlayerStylePlaylist } from '../../../../../interfaces/player/player.interface';

// mui
import { useTheme } from '@mui/material/styles';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { PlayArrowRounded } from '@mui/icons-material';

// actions
import { handleAudioSelect, handleTopicSelection } from '../../../playlistHandlers';
import { getTopicsFromAudioList } from '../../../../../utils/topicUtils';
import { Topic } from '../../../../../interfaces/player/playerRequest.interface';

export const PlaylistQueueList = ({ usePlayerTruncateValues = false }) => {
    const theme = useTheme();
    const { palette } = theme;

    const { appLang, appTopics, appModals } = useContext(AppContext);
    const { lang } = appLang;
    const { current: currentContextLanguage } = lang;
    const { topics: topicList } = appTopics;

    const { topicModal } = appModals;
    const { setOpenTopicModal } = topicModal;

    const playerContext = useContext(PlayerContext);
    const { style, data } = playerContext;

    const { audioQueue } = data as PlayerData;
    const { visual, playlist } = style;

    const { primaryColor, secondaryColor, truncatePlayerDescription, truncatePlayerTitle } = visual;

    const {
        showPlaylistAudioTitle,
        showPlaylistAudioDescription,
        showPlaylistAudioDate,
        showPlaylistAudioDuration,
        showPlaylistAudioNumber,
        showPlaylistAudioPublisher,
        showPlaylistAudioTopic,
        showPlaylistPlayIcon,
        showPlaylistImages,
        truncatePlaylistAudioTitle,
        truncatePlaylistAudioDescription,
    } = playlist as PlayerStylePlaylist;

    const languageAudios = audioQueue.filter(
        audio => audio.language === currentContextLanguage && audio.visible,
    );
    const topics: Topic[] = getTopicsFromAudioList(languageAudios);

    const handleTopicChange = (topicLabel: string, topicId: number) => {
        window.scrollTo(0, 0);
        handleTopicSelection(playerContext, currentContextLanguage, setOpenTopicModal, topicList, topicId, topicLabel);
    };

    return (
        <Box px={0} py={1}>
            {topics.map((topic, index) => (
                <Stack key={topic.label} spacing={0}>
                    {/* display topic */}
                    {languageAudios?.filter(
                        audio => audio.visible,
                        // audio.topicId === topic.id &&
                        // topic.id !== playerState.topicIndex
                    ).length > 0 && (
                        <Stack
                            direction="row"
                            sx={{
                                pl: 3,
                                paddingBottom: '6px',
                                mt: index > 0 ? 1 : 'inherit',
                                borderTop: index > 0 ? `1px solid ${palette.primary.light}` : 'inherit',
                            }}
                        >
                            {topics.length > 0 && (
                                <Button
                                    variant="outlined"
                                    size="small"
                                    id={`topic-button-${topic.label}`}
                                    sx={{
                                        borderColor: palette.primary.light,
                                        borderRadius: 2,
                                        color: palette.secondary.light,
                                        fontSize: '9pt',
                                        mt: 1,
                                        pb: 0,
                                        pt: '3px',
                                        px: '7px',
                                    }}
                                    onClick={() => topics.length > 1 && handleTopicChange(topic.label, topic.id)}
                                >
                                    {topic.id > -1 && <FormattedMessage id={`app.speech-topics-id-${topic.id}`} />}
                                    {topic.id === undefined && <>{topic.label}</>}
                                </Button>
                            )}
                        </Stack>
                    )}
                    {languageAudios?.filter(audio => audio.visible && audio.topicId === topic.id).length > 0 &&
                        audioQueue?.map(
                            audio =>
                                audio.visible &&
                                audio.topicId === topic.id && (
                                    <Box
                                        key={audio.id}
                                        component={'div'}
                                        width={1}
                                        pl={{ xs: -2, sm: -1, md: 0 }}
                                        pr={0}
                                        py={1}
                                        sx={{
                                            borderRadius: 2,
                                            cursor: 'pointer',
                                            '&:hover': {
                                                backgroundColor:
                                                    palette.mode === 'dark' ? palette.grey[900] : palette.grey[300],
                                            },
                                        }}
                                        onClick={() =>
                                            handleAudioSelect(audio.id, playerContext, currentContextLanguage)
                                        }
                                    >
                                        <Stack direction="row">
                                            {showPlaylistPlayIcon && (
                                                <Box display="flex" alignItems="center" justifyContent="center">
                                                    <IconButton
                                                        aria-label={`playlist-play-${audio.order}`}
                                                        disabled
                                                        sx={{ pl: 0 }}
                                                    >
                                                        <PlayArrowRounded />
                                                    </IconButton>
                                                </Box>
                                            )}
                                            {showPlaylistAudioNumber && (
                                                <Box display="flex" alignItems="center" justifyContent="center" pr={1}>
                                                    <Typography
                                                        aria-label={`playlist-order-${audio.order}`}
                                                        color={audio.selected ? primaryColor : secondaryColor}
                                                        fontWeight={600}
                                                        variant="body1"
                                                    >
                                                        {audio.order}
                                                    </Typography>
                                                </Box>
                                            )}

                                            <AudioDescriptor
                                                audio={audio}
                                                visualStyle={{
                                                    showTitle: showPlaylistAudioTitle,
                                                    showDescription: showPlaylistAudioDescription,
                                                    showDate: showPlaylistAudioDate,
                                                    showDuration: showPlaylistAudioDuration,
                                                    showPublisher: showPlaylistAudioPublisher,
                                                    showTopic: showPlaylistAudioTopic,
                                                    showImage: showPlaylistImages,
                                                    truncateTitle: usePlayerTruncateValues
                                                        ? truncatePlayerTitle
                                                        : truncatePlaylistAudioTitle,
                                                    truncateDescription: usePlayerTruncateValues
                                                        ? truncatePlayerDescription
                                                        : truncatePlaylistAudioDescription,
                                                    imageSize: '100px',
                                                    highlightSelectedAudio: true,
                                                }}
                                            />
                                        </Stack>
                                    </Box>
                                ),
                        )}
                </Stack>
            ))}
        </Box>
    );
};
