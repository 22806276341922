import { useContext, useLayoutEffect } from 'react';

// modules
import { FormattedMessage } from "react-intl";

// components
import { TopicModalCard } from './TopicModalCard';

// contexts
import { AppContext } from '../../../../contexts/AppContext';
import { PlayerContext } from '../../../../contexts/PlayerContext';

// mui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Grid,
    Modal,
    Stack,
    Typography,
} from '@mui/material';

// utils
import { setAudioFilterVisibility } from '../../../../utils/setAudioFilterVisibility';
import { handleTopicSelection } from '../../playlistHandlers';

// interfaces
import { PlayerData, PlayerProps, PlayerState } from '../../../../interfaces/player/player.interface';


interface Props {
    open: boolean,
    setOpen: (value: React.SetStateAction<boolean>) => void,
    allTopicLabel: string,
    topics: number[]
}


export const TopicModal = ({ open, setOpen, topics }: Props) => {
    const theme = useTheme();
    const { palette } = theme;

    const { appLang, appTopics, appModals } = useContext(AppContext);

    const { lang } = appLang;
    const { current: currentContextLanguage } = lang;

    const { topics: topicList } = appTopics;

    const { topicModal } = appModals;
    const { setOpenTopicModal } = topicModal;


    const playerContext = useContext(PlayerContext);
    const { data, props } = playerContext;

    const { audioQueue } = data as PlayerData;
    const {
        playerState,
        setPlayerState
    } = props as PlayerProps;

    const languageAudios = audioQueue.filter(audio => audio.language === currentContextLanguage);

    // add topic all (id 0) to topic list
    if (!topics.includes(0)) topics.push(0);

    const handleClose = () => setOpen(false);

    const handleTopicSelect = (topicId: number, topicLabel: string) => {
        handleTopicSelection(playerContext, currentContextLanguage, setOpenTopicModal, topicList, topicId, topicLabel);
    }


    useLayoutEffect(() => {
        if (playerState.topicIndex < 0) {
            const topicIndex = topicList[0]?.id || 0;
            const topic = topicList[0]?.label || '';

            setPlayerState(state => ({
                ...state,
                topicIndex,
                topic
            }))

            if (languageAudios) {
                setAudioFilterVisibility(currentContextLanguage, topic, languageAudios);
            }
        }
        // eslint-disable-next-line
    }, [playerState.topicIndex, setPlayerState])


    // set the default selected topic in the state
    useLayoutEffect(() => {
        if (playerState.topicIndex < 0) {
            const topicIndex = topicList[0]?.id || 0;
            const topic = topicList[0]?.label || '';

            setPlayerState((state: PlayerState) => ({
                ...state,
                topicIndex,
                topic
            }))
        }
    }, [playerState.topicIndex, languageAudios, setPlayerState, topics.length, topicList])

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ overflow: 'auto' }}
        >
            <Box
                sx={{
                    background: palette.background.paper,
                    m: '10vh',
                    p: 6,
                    pb: 9,
                    borderRadius: 10
                }}
            >
                <Stack
                    direction='column'
                    spacing={5}
                    alignItems='center'
                    justifyContent="center"
                >
                    <Typography variant='h3'>
                        <FormattedMessage id='app.speech-topics-line1' />
                    </Typography>

                    <TopicModalCard
                        key='all'
                        topicId={topicList[0]?.id || 0}
                        setModalOpen={setOpen}
                        handleTopicSelect={() => handleTopicSelect(topicList[0]?.id || 0, topicList[0]?.label || '')}
                    />

                    <Grid
                        container
                        spacing={0}
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                    >
                        {
                            topics.map(topic => (
                                topic !== 0 &&
                                <TopicModalCard
                                    key={topic}
                                    topicId={topic}
                                    setModalOpen={setOpen}
                                    handleTopicSelect={() => handleTopicSelect(topic || 0, topicList[topic]?.label || '')}
                                />
                            ))
                        }
                    </Grid>

                </Stack>
            </Box>
        </Modal>
    )
}
